export var headers = {
	"Project-Id-Version": "open-xchange-appsuite-gdpr",
	"Report-Msgid-Bugs-To": "",
	"POT-Creation-Date": "",
	"PO-Revision-Date": "2018-06-19 17:04+0200",
	"Last-Translator": "Viktor Pracht <viktor.pracht@open-xchange.com>",
	Language: "",
	"Language-Team": "",
	"Content-Type": "text/plain; charset=UTF-8",
	"Content-Transfer-Encoding": "8bit",
	"Plural-Forms": "nplurals=2; plural=(n != 1);",
	"MIME-Version": "1.0"
};
export default {
	headers: headers
};
